import { BillingPlan } from '@/features/billing';
import { userHasConsentedToStatistics } from '@/features/gdpr/utils/permissions';

const enabled = !!import.meta.env.VITE_GOOGLE_TAG_MANAGER_CONTAINER_ID;

export const trackVerifyEmail = () => {
  if (!enabled || !userHasConsentedToStatistics()) return;
  window.dataLayer.push({ event: 'verify_email' });
};

export const trackLogin = () => {
  if (!enabled || !userHasConsentedToStatistics()) return;
  window.dataLayer.push({ event: 'login' });
};

export const trackSwitchPlan = (plan: BillingPlan, total?: number) => {
  if (!enabled || !userHasConsentedToStatistics()) return;

  const value = total != null ? Math.floor(total / 100) : undefined;

  window.dataLayer.push({
    event: 'switch_plan',
    billing_plan: plan,
    purchase_value: value,
  });
};
